<template>
  <div class="map-chart">
    <highcharts
      :constructor-type="'mapChart'"
      :options="mapOptions"
      class="map"
    ></highcharts>
    <v-card class="countryInfos pa-2">
      <div v-if="worldData.length">
        <div class="my-2">
          <span class="font-weight-bold"> {{ selectedCountry.name }}</span>
        </div>

        <div class="city-name font-weight-bold my-3">Audience Count</div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in worldData[0].categories_count"
          :key="'Categories' + index"
        >
          <span>{{ item.category_name }} : {{ item.category_count }}</span>
          <br />
        </div>
        <div class="city-name font-weight-bold my-3">Top Domains</div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in worldData[0].domains"
          :key="'Domaine' + index"
        >
          <a target="_blank" :href="'http://'+item.split(':')[0]">{{ item.split(':')[0]}}</a>
          <span>: {{item.split(':')[1]}}</span>
          <br />
        </div>
        <div class="city-name font-weight-bold my-3">Classifications</div>
        <div
          class="city-infos-cats mt-1"
          v-for="(item, index) in worldData[0].classifications"
          :key="'class1' + index"
        >
         
          <span v-for="(item, i) in worldData[0].classifications[index]"
          :key="'class2' + i">{{ item }},</span>
          <br>
        </div>
      </div>
      <div v-else>
        <p class="city-infos-cats mt-5">No data are available</p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      defaultCountry: {},
      allWorldData: [],
      worldData: [],
      approch: this.$store.getters.getApproch,
      tier: this.$store.getters.getSegBtn,
      selectedCountry: {
        name: "United States",
      },
      countyCount:[],
      defData:[
              ["fo", 0],
              ["um", 0],
              ["us", 54569038],
              ["jp", 2982046],
              ["sc", 0],
              ["in", 31122039],
              ["fr", 1329003],
              ["fm", 0],
              ["cn", 5923051],
              ["pt", 217045],
              ["sw", 0],
              ["sh", 0],
              ["br", 12857047],
              ["ki", 0],
              ["ph", 11600087],
              ["mx", 6603000],
              ["es", 863013],
              ["bu", 0],
              ["mv", 331056],
              ["sp", 0],
              ["gb", 3084087],
              ["gr", 286017],
              ["as", 0],
              ["dk", 0],
              ["gl", 0],
              ["gu", 0],
              ["mp", 0],
              ["pr", 0],
              ["vi", 0],
              ["ca", 28880055],
              ["st", 0],
              ["cv", 0],
              ["dm", 0],
              ["nl", 208074],
              ["jm", 330037],
              ["ws", 0],
              ["om", 203016],
              ["vc", 0],
              ["tr", 5254056],
              ["bd", 4935086],
              ["lc", 0],
              ["nr", 0],
              ["no", 0],
              ["kn", 0],
              ["bh", 158072],
              ["to", 0],
              ["fi", 0],
              ["id", 10290078],
              ["mu", 353022],
              ["se", 0],
              ["tt", 290027],
              ["my", 7318023],
              ["pa", 270095],
              ["pw", 0],
              ["tv", 0],
              ["mh", 0],
              ["cl", 1983015],
              ["th", 4505048],
              ["gd", 0],
              ["ee", 0],
              ["ag", 0],
              ["tw", 3584006],
              ["bb", 0],
              ["it", 776007],
              ["mt", 0],
              ["vu", 0],
              ["sg", 0],
              ["cy", 0],
              ["lk", 1313096],
              ["km", 0],
              ["fj", 0],
              ["ru", 3513070],
              ["va", 0],
              ["sm", 0],
              ["kz", 275038],
              ["az", 214031],
              ["tj", 0],
              ["ls", 0],
              ["uz", 172019],
              ["ma", 684033],
              ["co", 1621043],
              ["tl", 0],
              ["tz", 395025],
              ["ar", 4544081],
              ["sa", 2067098],
              ["pk", 14821050],
              ["ye", 0],
              ["ae", 2878062],
              ["ke", 2204025],
              ["pe", 1199040],
              ["do", 422054],
              ["ht", 0],
              ["pg", 0],
              ["ao", 0],
              ["kh", 355009],
              ["vn", 2526034],
              ["mz", 124081],
              ["cr", 244050],
              ["bj", 0],
              ["ng", 7997100],
              ["ir", 210037],
              ["sv", 126010],
              ["sl", 0],
              ["gw", 0],
              ["hr", 0],
              ["bz", 0],
              ["za", 9984066],
              ["cf", 0],
              ["sd", 0],
              ["cd", 0],
              ["kw", 2220002],
              ["de", 171049],
              ["be", 204068],
              ["ie", 0],
              ["kp", 0],
              ["kr", 953038],
              ["gy", 148038],
              ["hn", 146081],
              ["mm", 115096],
              ["ga", 0],
              ["gq", 0],
              ["ni", 0],
              ["lv", 0],
              ["ug", 296046],
              ["mw", 117096],
              ["am", 247082],
              ["sx", 0],
              ["tm", 0],
              ["zm", 401030],
              ["nc", 0],
              ["mr", 0],
              ["dz", 268047],
              ["lt", 0],
              ["et", 0],
              ["er", 0],
              ["gh", 853022],
              ["si", 0],
              ["gt", 239022],
              ["ba", 175001],
              ["jo", 369066],
              ["sy", 0],
              ["mc", 0],
              ["al", 0],
              ["uy", 259078],
              ["cnm", 0],
              ["mn", 0],
              ["rw", 114044],
              ["so", 0],
              ["bo", 315084],
              ["cm", 0],
              ["cg", 0],
              ["eh", 0],
              ["rs", 559043],
              ["me", 0],
              ["tg", 0],
              ["la", 0],
              ["af", 0],
              ["ua", 3436019],
              ["sk", 0],
              ["jk", 0],
              ["bg", 0],
              ["qa", 779015],
              ["li", 0],
              ["at", 0],
              ["sz", 0],
              ["hu", 0],
              ["ro", 104028],
              ["ne", 0],
              ["lu", 0],
              ["ad", 0],
              ["ci", 139041],
              ["lr", 0],
              ["bn", 166085],
              ["iq", 575049],
              ["ge", 577025],
              ["gm", 0],
              ["ch", 0],
              ["td", 0],
              ["kv", 0],
              ["lb", 318059],
              ["dj", 0],
              ["bi", 0],
              ["sr", 0],
              ["il", 2060016],
              ["ml", 0],
              ["sn", 0],
              ["gn", 0],
              ["zw", 249097],
              ["pl", 0],
              ["mk", 0],
              ["py", 121086],
              ["by", 336049],
              ["cz", 0],
              ["bf", 0],
              ["na", 153023],
              ["ly", 0],
              ["tn", 120031],
              ["bt", 0],
              ["md", 121059],
              ["ss", 0],
              ["bw", 153066],
              ["bs", 100083],
              ["nz", 3464049],
              ["cu", 0],
              ["ec", 646024],
              ["au", 14292003],
              ["ve", 349059],
              ["sb", 0],
              ["mg", 0],
              ["is", 0],
              ["eg", 3325078],
              ["kg", 0],
              ["np", 1685077],
              ["hk", 1514000],
            ]
    };
  },
  mounted() {
    this.country_count();
    this.getWorldData();
  },
  methods: {
    async getWorldData() {
      await this.$http
        .post(
          "/sharethis_map_data?clustering_technique=" +
            this.approch +
            "&tier=" +
            this.tier
        )
        .then((res) => {
          console.log("/sharethis_map_data", res.data);
          this.allWorldData = res.data;
          this.loadInfos("United States");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async country_count() {
      await this.$http
        .post("/country_count")
        .then((res) => {
          console.log("/country_count", res.data);
          this.countyCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadInfos(location) {
      this.selectedCountry.flag = "ca";
      this.worldData = this.allWorldData.filter(
        (el) => el.location.toUpperCase() == location.toUpperCase()
      );
      console.log("load=<", this.worldData);
    },
    getInfos(e) {
      console.log(e);
      if (e.point.options["hc-key"] != "us") {
        this.selectedCountry.name = e.point.name;
        let location = e.point.name;
        this.selectedCountry.flag = e.point.options["hc-key"];
        this.worldData = this.allWorldData.filter(
          (el) => el.location.toUpperCase() == location.toUpperCase()
        );
      } else {
        this.$router.push({ path: "/categories-insights-us" });
      }
    },
  },
  computed: {
    mapOptions: function () {
      const that = this;
      return {
        chart: {
          map: worldMap,
          zoomType: "",
        },
        zomm: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },
         colorAxis: {
                min: 0,
                max: 54569038,
                minColor: '#FFFFFF',
                maxColor: '#76448A'
            },
        series: [
          {
            events: {
              click: function (e) {
                that.getInfos(e);
              },
            },
            name: "Random data",
            
            states: {
               hover: {
                color: "#512E5F"
              }
            },
            dataLabels: {
              enabled: false,
              format: "{point.name}",
            },
            allAreas: false,
            data: this.countyCount.length>0 ? this.countyCount : this.defData,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss">
.highcharts-credits {
  display: none;
}
.city-infos-cats * {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.city-name {
  font-size: 12px !important;
}
.countryInfos {
  font-size: 15px !important;
}
.map-chart {
  .countryInfos {
    position: absolute;
    width: 25%;
    left: 0;
    top: 15em;
  }
  .map {
    min-height: 500px;
  }
}
</style>