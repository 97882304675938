sc<template>
  <div id="categories-insights">
    <v-row class="mb-5">
      <v-col cols="12" sm="12">
        <div class="display-1 mb-5">
          Audience Distribution
          <v-btn
            @click="next()"
            class="float-right btns color-white"
            color="#94d227"
            elevation="0"
            >Next</v-btn>
            <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white mr-4"
            color="#94d227"
            elevation="0"
            >Back</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
          <WorldMap/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WorldMap from "./Charts/WorldMap.vue";
export default {
  name: "CategoriesInsights",
  components: { WorldMap },
  data() {
    return {
    };
  },
  methods: {
    async getInfos() {
      this.categoryEvent=this.categoryEvent?this.categoryEvent:"geo"
      await this.$http
        .post("/sharethis_insight_overtime?option="+this.categoryEvent)
        .then((res) => {
          console.log("/sharethis_insight_overtime",res.data);
          this.chart__data=res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    next() {
      this.$store.commit("setStepper", { type: "sharethis", val: 2 });
      this.$router.push({ path: "/event-insights" });
    },
  },
};
</script>

<style lang="scss" scoped>
#categories-insights::v-deep {
}
</style>